import * as React from 'react'
import { Helmet } from 'react-helmet'

import logo from '../images/logo.png'
import phone from '../images/phone-min.png'

import '../scss/index.scss'

const IndexPage = function() {
    return (
        <div className="index">
            <Helmet title="Reminder" defer={false} />

            <div className="wrapper">
                <div>
                    <a href="/" className="logo">
                        <img src={logo} alt="" />
                    </a>

                    <div className="phone-text">
                        <div className="phone">
                            <img src={phone} alt=""/>
                        </div>

                        <div className="text">
                            <h1>Endelig en app for dette!</h1>

                            <p>
                                Det er noe vi hører ofte! For hvor mange av oss har vel ikke glemt det gavekortet, eller gått glipp av en garanti som gikk ut for to uker siden? Legg inn kvitteringer med påminnelser på appen – og det samme med gavekortet- og få påminnelser i god tid før det går ut. Enklere blir det ikke.
                            </p>

                            <div className="download">
                                <h2>
                                    Last ned appen for:
                                </h2>
                                <p>
                                    <a href="https://apps.apple.com/us/app/reminder/id1553278723" target="_blank">iOS (App Store)</a> <span className="or">eller</span> <a href="https://play.google.com/store/apps/details?id=no.reminderapp" target="_blank">Android (Google Play)</a>
                                </p>
                            </div>
                            
                            <div className="download">
                                <h2>
                                    Følg oss:
                                </h2>
                                <p>
                                    <a href="https://www.instagram.com/reminderappen" target="_blank">Instagram</a>
                                </p>
                                <p>
                                    <a href="https://www.facebook.com/reminderappen" target="_blank">Facebook</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="submenu">
                <a href="/password-reset">Glemt passord?</a>
                <a href="/terms">Vilkår og personvern</a>
            </div>

        </div>
    )
}

export default IndexPage
